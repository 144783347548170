<template>
  <div class="glosario_cont" ref="glosario_help">
    
    <div class="glosario_body">
      <div>
        <div
          v-for="(tema, i) in glosario"
          :key="i"
          class="centroAyudaGlosarioItem"
          @click="changeTemaGlosarioSelected(i)"
        >
          <div
            class="centroAyudaGlosarioItem_row"
            :class="
              temaGlosarioSelected == i
                ? 'centroAyudaGlosarioItem_selected'
                : 'centroAyudaGlosarioItem_unSelected'
            "
          >
            <div>{{ t(`app.centro_ayuda.glosar.${tema.id}.req`) }}</div>
            <div>
              <img
                :src="iconChevronArribaRojo"
                alt=""
                srcset=""
                v-if="temaGlosarioSelected == i"
              />
              <img :src="iconChevronAbajoGris" alt="" srcset="" v-else />
            </div>
          </div>

          <div
            v-if="temaGlosarioSelected == i"
            class="centroAyudaGlosarioItem_detalle"
          >
            {{ t(`app.centro_ayuda.glosar.${tema.id}.res`) }}
          </div>
        </div>

        <!-- <div v-for="(item, w) in glosario" :key="w" class="glosario_item">
          <select-comp
            :item="item"
            :item_selected="item_selected"
            :id="item.id"
            text_color="#787878"
            @setItemSelected="setItemSelected"
          />
        </div> -->
        <!-- <div v-if="ver_mas">
          <div
            v-for="(item, w) in glosario_extra"
            :key="w + 1000"
            class="glosario_item"
          >
            <select-comp
              :item="item"
              :item_selected="item_selected"
              :id="item.id"
              text_color="#787878"
              @setItemSelected="setItemSelected"
            />
          </div>
        </div> -->

        <div class="glosario_ver_mas">
          <verMas
            :ver_mas="ver_mas"
            @ver_mas_toggle="ver_mas_toggle"
            :blur="false"
            back_color=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import selectComp from "../generales/selectComp.vue";
// import verMas from "../generales/verMas.vue";
import { mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {},
  data() {
    return {
      iconChevronArribaRojo: require("../../assets/imgs/centroAyuda/iconChevronArribaRojo.svg"),
      iconChevronAbajoGris: require("../../assets/imgs/centroAyuda/iconChevronAbajoGris.svg"),
      temaGlosarioSelected: -1,
      glosario: [
        {
          id: "cuenta_rut",
        },
        {
          id: "cuenta_vista",
        },
        {
          id: "remitente",
        },
        {
          id: "destinatario",
        },
        // ],
        // glosario_extra: [
        {
          id: "transferencia",
        },
        {
          id: "tasa_cambio",
        },
        {
          id: "costo_envio",
        },
        {
          id: "remesa",
        },
        {
          id: "rut",
        },
        {
          id: "moneda_destino",
        },
        {
          id: "retiro_caja",
        },
        {
          id: "uaf",
        },
        {
          id: "oficial_cumplimiento",
        },
        {
          id: "cci",
        },
      ],
      item_selected: "",
      ver_mas: false,
    };
  },
  computed: {
    ...mapState([""]),
  },
  methods: {
    setItemSelected(id) {
      this.item_selected = id;
    },
    
    changeTemaGlosarioSelected(index) {
      if (this.temaGlosarioSelected == index) {
        this.temaGlosarioSelected = -1;
      } else {
        this.temaGlosarioSelected = index;
      }
    },
  },
};
</script>

<style>
.centroAyudaGlosarioItem_row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.centroAyudaGlosarioItem_detalle {
  margin-top: 0.8rem;
  padding-bottom: 0.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.5px;
  color: #787878;
}

.glosario_cont {
  /* padding: 3.5rem 2rem;
  background: rgba(225, 225, 225, 0.4); */
}
/* .glosario_title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #383838;
}
.glosario_sub_title {
  padding-top: 0.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
} */
.glosario_body {
  /* padding-top: 2.75rem; */
}
.glosario_item {
  padding-bottom: 0.43rem;
}
.glosario_ver_mas {
  padding-top: 0.5rem;
  padding-right: 1rem;
}

/* ********************************************** */
@media (min-width: 768px) {
  .glosario_head {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  /* .glosario_title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
  .glosario_sub_title {
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  } */
  .glosario_cont {
    /* padding-left: 10%;
    padding-right: 10%; */
  }
}

/* ***************************************************** */
@media (min-width: 1200px) {
  /* .glosario_title {
    font-size: 40px;
    line-height: 47px;
  }
  .glosario_sub_title {
    font-size: 18px;
    line-height: 27px;
  } */
  /* .glosario_cont {
    padding-left: 20%;
    padding-right: 20%;
  }
  .glosario_sub_title {
    width: 80%;
  } */
}
</style>