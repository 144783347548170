<template>
    <div class="faq__cont">
        <div class="faq__p1 faq__separador">
            a.-&nbsp;{{ t("app.centro_ayuda.faq.modo_uso_gf.t1_a") }}&nbsp;
            <span class="faq__t_azul faq__t_cortado"><a href="https://www.girofacil.com/"  target="_blank">www.girofacil.com</a></span>&nbsp;
            {{ t("app.centro_ayuda.faq.modo_uso_gf.t1_b") }}&nbsp;
            <span class="faq__t_azul faq__t_cortado faq__t_cortado"><a href="https://apps.apple.com/cl/app/girofacil/id1279339598"  target="_blank">https://apps.apple.com/cl/app/girofacil/id1279339598</a> </span>
            &nbsp;ó&nbsp;
            <span class="faq__t_azul faq__t_cortado faq__t_cortado"><a href="https://play.google.com/store/apps/details?id=cl.girofacil.girofacil"  target="_blank">https://play.google.com/store/apps/details?id=cl.girofacil.girofacil</a> </span>
            </div>
        <div class="faq__p1 faq__separador">b.-&nbsp;{{ t("app.centro_ayuda.faq.modo_uso_gf.t2") }}</div>
        <div class="faq__p1 faq__separador">c.-&nbsp;{{ t("app.centro_ayuda.faq.modo_uso_gf.t3") }}</div>
        <div class="faq__p1 faq__separador">d.-&nbsp;{{ t("app.centro_ayuda.faq.modo_uso_gf.t4") }}</div>
        <div class="faq__p1 faq__separador">e.-&nbsp;{{ t("app.centro_ayuda.faq.modo_uso_gf.t5") }}</div>
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.modo_uso_gf.t6") }}</div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    setup() {
    const { t } = useI18n();
    return { t };
  },

}
</script>

<style>

</style>