<template>
  <div class="manual_preven_delitocont">
    <div class="ancho_acotado">
      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">1.-&nbsp;{{ t("app.manual_preven_delito.p_1_tit") }}</div>
        {{ t("app.manual_preven_delito.p_1_1") }}
      </div>

      <div class="manual_preven_delitoseparador">
        {{ t("app.manual_preven_delito.p_1_2") }}
      </div>
      <div class="manual_preven_delitoseparador">
        {{ t("app.manual_preven_delito.p_1_3") }}
      </div>
      <div class="manual_preven_delitoseparador">
        {{ t("app.manual_preven_delito.p_1_4") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">2.-&nbsp;{{ t("app.manual_preven_delito.p_2_tit") }}</div>
        {{ t("app.manual_preven_delito.p_2_1") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">3.-&nbsp;{{ t("app.manual_preven_delito.p_3_tit") }}</div>
        {{ t("app.manual_preven_delito.p_3_1") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">4.-&nbsp;{{ t("app.manual_preven_delito.p_4_tit") }}</div>
        <div class="manual_preven_delitobold">4.1.-&nbsp;{{ t("app.manual_preven_delito.p_4_1_tit") }}</div>
        {{ t("app.manual_preven_delito.p_4_1_1") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">4.2.-&nbsp;{{ t("app.manual_preven_delito.p_4_2_tit") }}</div>
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_1_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_1_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_2_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_2_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_3_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_3_b") }}
        <div class="manual_preven_tab_left"><span class="manual_preven_delitobold">1)&nbsp;</span>{{ t("app.manual_preven_delito.p_4_2_3_c") }}</div>
        <div class="manual_preven_tab_left"><span class="manual_preven_delitobold">2)&nbsp;</span>{{ t("app.manual_preven_delito.p_4_2_3_d") }}</div>
        <div class="manual_preven_tab_left"><span class="manual_preven_delitobold">3)&nbsp;</span>{{ t("app.manual_preven_delito.p_4_2_3_e") }}</div>
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_4_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_4_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_5_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_5_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_6_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_6_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_7_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_7_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_8_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_8_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_9_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_9_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_10_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_10_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_11_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_11_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_12_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_12_b") }}
      </div>
      <div class="manual_preven_delitoseparador">
        <span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_4_2_13_a") }}</span>
        &nbsp;{{ t("app.manual_preven_delito.p_4_2_13_b") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">4.3.-&nbsp;{{ t("app.manual_preven_delito.p_4_3_tit") }}</div>
      </div>
      <div class="manual_preven_delitoseparador">
        {{ t("app.manual_preven_delito.p_4_3_1") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">4.4.-&nbsp;{{ t("app.manual_preven_delito.p_4_4_tit") }}</div>
      </div>
      <div class="manual_preven_delitoseparador">
        {{ t("app.manual_preven_delito.p_4_4_1") }}
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">5.-&nbsp;{{ t("app.manual_preven_delito.p_5_tit") }}</div>
      </div>
      <div class="">{{ t("app.manual_preven_delito.p_5_a") }}</div>
      <div class="">{{ t("app.manual_preven_delito.p_5_b_1") }}</div>
      <div class="">{{ t("app.manual_preven_delito.p_5_b_2") }}</div>
      <div class="">{{ t("app.manual_preven_delito.p_5_b_3") }}</div>
      <div class="">{{ t("app.manual_preven_delito.p_5_b_4") }}</div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">5.1.-&nbsp;{{ t("app.manual_preven_delito.p_5_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_5_1_1") }}</div>
      </div>
      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">5.2.-&nbsp;{{ t("app.manual_preven_delito.p_5_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_5_2_1") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">5.3.-&nbsp;{{ t("app.manual_preven_delito.p_5_3_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_5_3_1") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_3_2_a") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_3_2_b") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_3_2_c") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_3_2_d") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_3_2_e") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_3_2_f") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">5.4.-&nbsp;{{ t("app.manual_preven_delito.p_5_4_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_5_4_1") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_a") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_b") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_c") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_d") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_e") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f") }}</div>
        <div class="manual_preven_tab_left">
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_1") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_2") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_3") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_4") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_5") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_6") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_f_7") }}</div>
          </div>
      </div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_g") }}</div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_h") }}</div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_i") }}</div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_j") }}</div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_k") }}</div>
      <div class="manual_preven_tab_left">
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_k_1") }}</div>
          <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_k_2") }}</div>
      </div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_l") }}</div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_m") }}</div>
      <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_5_4_2_n") }}</div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.-&nbsp;{{ t("app.manual_preven_delito.p_6_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.1.-&nbsp;{{ t("app.manual_preven_delito.p_6_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_1_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.1.1.-&nbsp;{{ t("app.manual_preven_delito.p_6_1_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_1_1_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_1_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_1_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_1_b_3") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.1.2.-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_1_2_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_b_1") }}</div>
        <div class="manual_preven_delitoseparador manual_preven_tab_left ">
          <table>
            <thead>
              <th><span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_6_1_2_b_1_th1") }}</span></th>
              <th><span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_6_1_2_b_1_th2") }}</span></th>
              <th><span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_6_1_2_b_1_th3") }}</span></th>
            </thead>
            <tbody>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr1_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr1_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr1_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr2_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr2_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr2_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr3_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr3_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr3_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr4_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr4_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr4_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr5_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr5_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_1_tr5_3") }}</td></tr>
            </tbody>
          </table>
        </div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_b_2") }}</div>
        <div class="manual_preven_delitoseparador manual_preven_tab_left ">
          <table>
            <thead>
              <th><span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_6_1_2_b_2_th1") }}</span></th>
              <th><span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_6_1_2_b_2_th2") }}</span></th>
              <th><span class="manual_preven_delitobold">{{ t("app.manual_preven_delito.p_6_1_2_b_2_th3") }}</span></th>
            </thead>
            <tbody>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr1_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr1_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr1_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr2_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr2_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr2_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr3_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr3_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr3_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr4_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr4_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr4_3") }}</td></tr>
              <tr><td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr5_1") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr5_2") }}</td>
              <td>{{ t("app.manual_preven_delito.p_6_1_2_b_2_tr5_3") }}</td></tr>
            </tbody>
          </table>
        </div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_1_2_c") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_4") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_5") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_6") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_7") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_8") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_9") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_2_c_10") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_1_2_d") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_1") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_2") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_3") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_4") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_5") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_6") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_7") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_6_1_2_d_8") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.1.2.-&nbsp;{{ t("app.manual_preven_delito.p_6_1_3_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_1_3_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_3_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_3_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_3_b_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_1_3_b_4") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_1_3_c") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.2.-&nbsp;{{ t("app.manual_preven_delito.p_6_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_2_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.2.1.-&nbsp;{{ t("app.manual_preven_delito.p_6_2_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_2_1_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.2.2.-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_2_2_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_b_1_a") }}</div>
         &nbsp;(https://denunciasgirofacil.com/)
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_b_1_b") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_b_2") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_2_2_c") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_c_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_c_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_2_c_3") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.2.3.-&nbsp;{{ t("app.manual_preven_delito.p_6_2_3_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_2_3_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_3_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_3_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_2_3_b_3") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.2.4.-&nbsp;{{ t("app.manual_preven_delito.p_6_2_4_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_2_4_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.3.-&nbsp;{{ t("app.manual_preven_delito.p_6_3_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_3_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.3.1.-&nbsp;{{ t("app.manual_preven_delito.p_6_3_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_3_1_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.3.2.-&nbsp;{{ t("app.manual_preven_delito.p_6_3_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_3_2_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_2_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_2_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_2_b_3") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_3_2_c") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.3.3.-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_3_3_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_b_3") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_3_3_c") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_4") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_5") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_6") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_7") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_8") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_9") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_3_3_c_10") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.3.4.-&nbsp;{{ t("app.manual_preven_delito.p_6_3_4_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_3_4_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.3.5.-&nbsp;{{ t("app.manual_preven_delito.p_6_3_5_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_3_5_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.4.-&nbsp;{{ t("app.manual_preven_delito.p_6_4_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_4_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.4.1.-&nbsp;{{ t("app.manual_preven_delito.p_6_4_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_4_1_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_1_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_1_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_1_b_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_1_b_4") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">6.4.2.-&nbsp;{{ t("app.manual_preven_delito.p_6_4_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_6_4_2_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_2_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_2_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_6_4_2_b_3") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_6_4_2_c") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.-&nbsp;{{ t("app.manual_preven_delito.p_7_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_7_a") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.1.-&nbsp;{{ t("app.manual_preven_delito.p_7_1_tit") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.1.1.-&nbsp;{{ t("app.manual_preven_delito.p_7_1_1_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_7_1_1_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_1_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_1_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_1_b_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_1_b_4") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.1.2.-&nbsp;{{ t("app.manual_preven_delito.p_7_1_2_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_7_1_2_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_2_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_2_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_2_b_3") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.1.3.-&nbsp;{{ t("app.manual_preven_delito.p_7_1_3_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_7_1_3_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_3_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_3_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_3_b_3") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.1.4.-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_7_1_4_a") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_7_1_4_b_1") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_7_1_4_b_2") }}</div>
        <div class="manual_preven_tab_left">{{ t("app.manual_preven_delito.p_7_1_4_b_3") }}</div>
        <div class="manual_preven_tab_left">
          <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_b_3_1") }}</div>
          <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_b_3_2") }}</div>
          <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_b_3_3") }}</div>
          <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_b_3_4") }}</div>
          <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_b_3_5") }}</div>
          <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_4_b_3_6") }}</div>
        </div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">7.1.5.-&nbsp;{{ t("app.manual_preven_delito.p_7_1_5_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_7_1_5_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_5_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_5_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_5_b_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_5_b_4") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_7_1_5_b_5") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">8.-&nbsp;{{ t("app.manual_preven_delito.p_8_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_8_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_4") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_5") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_6") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_8_b_7") }}</div>
        <div class="manual_preven_delitoseparador">{{ t("app.manual_preven_delito.p_8_c") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">9.-&nbsp;{{ t("app.manual_preven_delito.p_9_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_9_a") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_9_b_1") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_9_b_2") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_9_b_3") }}</div>
        <div class="manual_preven_tab_left">-&nbsp;{{ t("app.manual_preven_delito.p_9_b_4") }}</div>
      </div>

      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">10.-&nbsp;{{ t("app.manual_preven_delito.p_10_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_10_a") }}</div>
      </div>


      <div class="manual_preven_delitoseparador">
        <div class="manual_preven_delitobold">11.-&nbsp;{{ t("app.manual_preven_delito.p_11_tit") }}</div>
        <div class="">{{ t("app.manual_preven_delito.p_11_a") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    ...mapState(["montos_pais_docs"]),
  },
  methods: {
    ...mapActions([""]),

    format_number(numerito) {
      if (numerito && numerito != "") {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
    },
  },
};
</script>

<style>
.manual_preven_delitocont {
  padding: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #383838;
}
.manual_preven_delitocont_wide {
  padding-left: 1rem;
  padding-right: 1rem;
}
.manual_preven_delitobold {
  font-weight: 500;
}
.manual_preven_delitoseparador {
  padding-top: 1rem;
}
.faq__table1 {
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #383838;
}
.faq__table1 th {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #383838;
  width: 20%;
  max-width: 20%;
}
.manual_preven_tab_left{
  padding-left: 1rem;
}

 th, td {
  border: 1px solid ;
  padding: 0.2rem;
}
</style>