<template>
  <screen_width_cont>
    <div class="centroAyudaBody xyz" ref="section">
      <div class="cont_300_basico ancho_acotado">
        <div class="header_top">
          <headerBack @back="backLocal" />
        </div>

        <div class="centroAyudaIcon">
          <img :src="iconInformacion" alt="" srcset="" />
        </div>

        <div class="centroAyudaTitle title">
          {{ t("app.centro_ayuda.tit") }}
        </div>

        <div class="centroAyuda_tema">
          <div class="centroAyudaItem clickable" @click="changeItemSelected(1)">
            <div
              class="centroAyudaItemTitle "
              :class="itemSelected == 1 ? 'centroAyudaItemTitleSelected' : ''"
            >
              {{ t("app.centro_ayuda.hablemos") }}
            </div>
            <div class="centroAyudaItemChevron">
              <img
                :src="iconChevronArribaRojo"
                alt=""
                srcset=""
                v-if="itemSelected == 1"
              />
              <img :src="iconChevronAbajoGris" alt="" srcset="" v-else />
            </div>
          </div>
          <div v-if="itemSelected == 1">
            <div class="centroAyudaText centroAyudaHablemosText">
              {{ t("app.centro_ayuda.hablem.texto") }}
            </div>
            <div class="centroAyudaHablemosWA centroAyudaHablemosText" @click="call_whatsapp">
              <img :src="iconWhatsApp_1" alt="" srcset="" />
            </div>
          </div>
        </div>

        <div class="centroAyuda_tema">
          <div class="centroAyudaItem clickable" @click="changeItemSelected(2)">
            <div
              class="centroAyudaItemTitle"
              :class="itemSelected == 2 ? 'centroAyudaItemTitleSelected' : ''"
            >
              {{ t("app.centro_ayuda.escribenos") }}
            </div>
            <div class="centroAyudaItemChevron">
              <img
                :src="iconChevronArribaRojo"
                alt=""
                srcset=""
                v-if="itemSelected == 2"
              />
              <img :src="iconChevronAbajoGris" alt="" srcset="" v-else />
            </div>
          </div>
          <div v-if="itemSelected == 2">
            <div class="centroAyudaText">
              {{ t("app.centro_ayuda.escribe.text") }}
            </div>
            <div class="centroAyudaHablemosMsg" :class="stateInput">
              <textarea
                v-model="msgText"
                name="Text1"
                cols="40"
                rows="5"
                maxlength="300"
                :placeholder="t('app.centro_ayuda.escribe.mensaje')"
                @focus="cambiarType($event)"
                @blur="cambiarType($event)"
              ></textarea>
            </div>
            <div class="centroAyudaItemBtn1">
              <btnAction
                :textBtn="t('app.comunes.btns.enviar')"
                :disabled="false"
                @action="sendMsg"
                colorBack="azul"
                colorText="blco"
                :estadoBtn="estadoBtn"
                :msg_completar="t('app.comunes.btns.escriba_mensaje')"
              />
            </div>
          </div>
        </div>

        <div class="centroAyuda_tema">
          <div class="centroAyudaItem clickable" @click="changeItemSelected(3)">
            <div
              class="centroAyudaItemTitle"
              :class="itemSelected == 3 ? 'centroAyudaItemTitleSelected' : ''"
            >
              {{ t("app.centro_ayuda.glosario") }}
            </div>
            <div class="centroAyudaItemChevron">
              <img
                :src="iconChevronArribaRojo"
                alt=""
                srcset=""
                v-if="itemSelected == 3"
              />
              <img :src="iconChevronAbajoGris" alt="" srcset="" v-else />
            </div>
          </div>
          <div class="centroAyudaGlosarioBody" v-if="itemSelected == 3">
            <glosario />
          </div>
        </div>

        <div class="centroAyuda_tema">
          <div class="centroAyudaItem clickable" @click="changeItemSelected(4)">
            <div
              class="centroAyudaItemTitle"
              :class="itemSelected == 4 ? 'centroAyudaItemTitleSelected' : ''"
            >
              {{ t("app.centro_ayuda.faq_tit") }}
            </div>
            <div class="centroAyudaItemChevron" @click="changeItemSelected(4)">
              <img
                :src="iconChevronArribaRojo"
                alt=""
                srcset=""
                v-if="itemSelected == 4"
              />
              <img :src="iconChevronAbajoGris" alt="" srcset="" v-else />
            </div>
          </div>
          <div v-if="itemSelected == 4">
            <div class="">
              <faq />
            </div>
          </div>
        </div>

        <!-- CUMPLIMIENTO Y LEGALES -->
        <div class="centroAyuda_tema cumpli_legales">
          <div class="centroAyudaItem clickable" @click="changeItemSelected(5)">
            <div
              class="centroAyudaItemTitle"
              :class="itemSelected == 5 ? 'centroAyudaItemTitleSelected' : ''"
            >
              {{ t("app.centro_ayuda.cumplim_legales_tit") }}
            </div>
            <div class="centroAyudaItemChevron" @click="changeItemSelected(5)">
              <img
                :src="iconChevronArribaRojo"
                alt=""
                srcset=""
                v-if="itemSelected == 5"
              />
              <img :src="iconChevronAbajoGris" alt="" srcset="" v-else />
            </div>
          </div>
          <div v-show="itemSelected == 5">
            <div class="">
              <!-- *** -->
              <div class="centroAyudaP_privacidad" ref="politicas_priv">
                <div
                  class="centroAyudaDocs clickable"
                  :class="
                    politica_desplegada == 1
                      ? 'centroAyudaItemTitleSelected'
                      : 'centroAyudaItemTitleUnselected'
                  "
                  ref=""
                  @click="setPolitica_desplegada(1)"
                >
                  {{ t("app.politica_privac_v2.tit") }}
                </div>
                <div
                  class="centroAyudaT_condiciones_text"
                  v-if="politica_desplegada == 1"
                >
                  <politicas_privacidad />
                </div>
              </div>

              <div
                class="centroAyudaT_condiciones"
                ref="term_condi"
                id="term_condi"
              >
                <div
                  class="centroAyudaDocs clickable"
                  :class="
                    politica_desplegada == 3
                      ? 'centroAyudaItemTitleSelected'
                      : 'centroAyudaItemTitleUnselected'
                  "
                  @click="setPolitica_desplegada(3)"
                >
                  {{ t("app.term_cond.tit") }}
                </div>
                <div
                  class="centroAyudaT_condiciones_text"
                  v-if="politica_desplegada == 3"
                >
                  <terminos />
                  <div
                    class="centroAyuda_term_cond_btn"
                    v-if="show_btn_term_cond"
                  >
                    <btnAction
                      :textBtn="t('app.comunes.btns.aceptar')"
                      :disabled="false"
                      @action="aceptar_term_cond"
                      colorBack="azul"
                      colorText="blco"
                      estadoBtn="active"
                      
                    />
                  </div>
                </div>
              </div>

              <!-- Manual prevencion del delito -->
              <div class="centroAyudaP_privacidad" ref="manual_preven_delito">
                <div
                  class="centroAyudaDocs clickable"
                  :class="
                    politica_desplegada == 4
                      ? 'centroAyudaItemTitleSelected'
                      : 'centroAyudaItemTitleUnselected'
                  "
                  ref=""
                  @click="setPolitica_desplegada(4)"
                >
                  {{ t("app.manual_preven_delito.tit") }}
                </div>
                <div
                  class="centroAyudaT_condiciones_text"
                  v-if="politica_desplegada == 4"
                >
                  <manual_prevencion_delito />
                </div>
              </div>
              <!-- manual_cod_etica_conducta -->
              <div
                class="centroAyudaP_privacidad"
                ref="manual_cod_etica_conducta"
              >
                <div
                  class="centroAyudaDocs clickable"
                  :class="
                    politica_desplegada == 5
                      ? 'centroAyudaItemTitleSelected'
                      : 'centroAyudaItemTitleUnselected'
                  "
                  ref=""
                  @click="setPolitica_desplegada(5)"
                >
                  {{ t("app.manual_cod_etica_conducta.tit") }}
                </div>
                <div
                  class="centroAyudaT_condiciones_text"
                  v-if="politica_desplegada == 5"
                >
                  <manual_cod_etica_conducta />
                </div>
              </div>

              <!-- declara_origen_fondos -->
              <div class="centroAyudaP_privacidad" ref="declara_origen_fondos">
                <div
                  class="centroAyudaDocs clickable"
                  :class="
                    politica_desplegada == 6
                      ? 'centroAyudaItemTitleSelected'
                      : 'centroAyudaItemTitleUnselected'
                  "
                  ref=""
                  @click="setPolitica_desplegada(6)"
                >
                  {{ t("app.declara_origen_fondos.tit") }}
                </div>
                <div
                  class="centroAyudaT_condiciones_text"
                  v-if="politica_desplegada == 6"
                >
                  <div class="centro_ayuda_descarga ">
                    <div class="textUnderLine clickable"  @click="descargar_doc(6)">{{ t("app.comunes.btns.descargar") }}</div>&nbsp;&nbsp;&nbsp;
                    <img :src="pdf" alt="" class="clickable"  @click="descargar_doc(6)">
                  </div>
                </div>
              </div>

              <!-- declara_pep -->
              <div class="centroAyudaP_privacidad" ref="declara_pep">
                <div
                  class="centroAyudaDocs clickable"
                  :class="
                    politica_desplegada == 7
                      ? 'centroAyudaItemTitleSelected'
                      : 'centroAyudaItemTitleUnselected'
                  "
                  ref=""
                  @click="setPolitica_desplegada(7)"
                >
                  {{ t("app.declara_pep.tit") }}
                </div>
                <div
                  class="centroAyudaT_condiciones_text"
                  v-if="politica_desplegada == 7"
                >
                  <div class="centro_ayuda_descarga">
                    <div class="textUnderLine clickable" @click="descargar_doc(7)">{{ t("app.comunes.btns.descargar") }}</div>&nbsp;&nbsp;&nbsp;
                    <img :src="pdf" alt="" class="clickable"  @click="descargar_doc(7)">
                  </div>
                </div>
              </div>
              <!-- *** -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { menuController, alertController } from "@ionic/vue";
import headerBack from "../components/generales/headerBack.vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import terminos from "../components/centro_ayuda/terminos.vue";
import politicas_privacidad from "../components/centro_ayuda/politicas_privacidad.vue";
import glosario from "../components/centro_ayuda/glosario.vue";
import faq from "../components/centro_ayuda/faq.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import manual_prevencion_delito from "../components/centro_ayuda/manual_prevencion_delito.vue";
import manual_cod_etica_conducta from "../components/centro_ayuda/manual_cod_etica_conducta.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "centroAyuda",
  components: {
    headerBack,
    btnAction,
    terminos,
    politicas_privacidad,
    glosario,
    faq,
    screen_width_cont,
    manual_prevencion_delito,
    manual_cod_etica_conducta,
  },
  data() {
    return {
      iconInformacion: require("../assets/imgs/centroAyuda/iconInformacion.svg"),
      iconBuscar: require("../assets/imgs/centroAyuda/iconBuscar.svg"),
      iconChevronAbajoGris: require("../assets/imgs/centroAyuda/iconChevronAbajoGris.svg"),
      iconChevronArribaRojo: require("../assets/imgs/centroAyuda/iconChevronArribaRojo.svg"),
      itemSelected: 1,
      iconWhatsApp_1: require("../assets/imgs/iconWhatsApp_1.svg"),
      stateInput: "inicio",
      msgText: "",
      pdf:require("../assets/imgs/centroAyuda/pdf.png"),
      politica_desplegada: -1,
      show_btn_term_cond: false,
      declaracion_origen_de_fondos:require("../assets/data/DECLARACION ORIGEN DE FONDOS.pdf"),
      declaracion_pep:require("../assets/data/Declaracion_PEP.pdf"),
    };
  },
  computed: {
    ...mapState([
      "configuracion",
      "menuSpDesde",
      "desde",
      "nav_stack",
      "widthScreen",
      "plataforma",
    ]),

    
    estadoBtn() {
      let aux = "inactive";
      if (this.msgText != "") {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["set_accept_term_cond", "set_nav_stack", "setshowSpinner"]),
    ...mapActions(["download", "enviar_mensaje_sac"]),

    backLocal() {
    //  console.log(this.nav_stack);
      if (this.nav_stack.menuSp) {
        menuController.open("firstMenu");
      }
      let aux = [...this.nav_stack.desde];
      let destino = aux.pop();
      this.set_nav_stack({ desde: aux, menuSp: this.nav_stack.menuSp });
      this.$router.push({ name: destino, replace: true });
    },
    call_whatsapp(){
      if(this.plataforma == "web"){
        console.log("call_whatsapp from web");
      }else{
        console.log("llamar WhatsApp Mobile");
      }
      
    },
    changeItemSelected(item) {
      // console.log("changeItemSelected");
      if (this.itemSelected == item) {
        this.itemSelected = 0;
      } else {
        this.itemSelected = item;
      }
      if (this.itemSelected == 0 || this.itemSelected != 3) {
        this.temaGlosarioSelected = -1;
      }
      if (this.itemSelected != 0) {
        this.politica_desplegada = -1;
      }
    },
    cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
      } else if (evento == "blur") {
        this.stateInput = "post";
      }
    },
    sendMsg() {
      console.log(this.msgText);
      this.setshowSpinner(false);
      this.enviar_mensaje_sac(this.msgText);
      this.msgText = "";
      this.presentAlert();
    },
    async presentAlert() {
      let alert = await alertController.create({
        // header: this.title,
        subHeader: this.t("app.centro_ayuda.escribe.mensaje_enviado"),
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();
    },
    changeTemaGlosarioSelected(index) {
      if (this.temaGlosarioSelected == index) {
        this.temaGlosarioSelected = -1;
      } else {
        this.temaGlosarioSelected = index;
      }

    },
    setPolitica_desplegada(politica) {
      console.log(
        "setPolitica_desplegada",
        politica,
        this.politica_desplegada,
        this.itemSelected
      );
      if (this.politica_desplegada == politica) {
        this.politica_desplegada = -1;
      } else {
        this.politica_desplegada = politica;
      }
      
    },
    aceptar_term_cond() {
      this.setshowSpinner(false);
      this.set_accept_term_cond(true);
      this.$router.push({ name: "registroDisclaimer", replace: true });
    },
    descargar_doc(doc){
      if(doc == 6){
          window.open(this.declaracion_origen_de_fondos);
      }else if(doc == 7){
          window.open(this.declaracion_pep);
      }
    }
  },
  watch: {
    politica_desplegada() {
      if (this.politica_desplegada == 1) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["politicas_priv"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 100);
      } else if (this.politica_desplegada == 2) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["politica_seguridad"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 500);
      } else if (this.politica_desplegada == 3) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["term_condi"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 100);
      } else if (this.politica_desplegada == 4) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["manual_preven_delito"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 100);
      } else if (this.politica_desplegada == 5) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["manual_cod_etica_conducta"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 100);
      } else if (this.politica_desplegada == 6) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["declara_origen_fondos"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 100);
      } else if (this.politica_desplegada == 7) {
        setTimeout(() => {
          this.$refs["section"].scrollTo({
            top: this.$refs["declara_pep"].offsetTop - 16,
            behavior: "smooth",
          });
        }, 100);
      }
    },
  },

  ionViewDidEnter() {
    // console.log(this.menuSpDesde, this.desde);
    // if (this.$route.params.tema == "3") {
    //   this.politica_desplegada = 3;
    //   this.show_btn_term_cond = true;
    //   setTimeout(() => {
    //     this.$refs["section"].scrollTo({
    //       top: this.$refs["term_condi"].offsetTop - 16,
    //       behavior: "smooth",
    //     });
    //   }, 100);
    // }
    // this.set_accept_term_cond(false);
  },
  ionViewWillLeave() {},
};
</script>

<style>
.centroAyudaIcon {
  margin-top: 1.8rem;
  display: flex;
  justify-content: center;
  position: relative;
}
/* .centroAyudaIcon div{
    width: fit-content;
    position: relative;
    border: 1px solid green;
}
.centroAyudaIcon div img{
    position: absolute;
    width: 2rem;
    height: 2rem;
} */
.centroAyudaTitle {
  margin-top: 1rem;

  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px; */
  text-align: center;
  letter-spacing: 0.5px;
  color: #614ad3;
}
.centroAyudaBuscar {
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  background: #ffffff;
  border: 1.5px solid #787878;
  box-shadow: 0px 4px 8px rgba(128, 27, 28, 0.1);
  border-radius: 10px;
  height: 2.0625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.centroAyudaBuscarInput input {
  border: none;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #787878;
}
.centroAyudaBuscarInput input:focus {
  outline: none;
}
.centroAyudaBuscarImg {
  width: 1rem;
}
.centroAyuda_tema {
  margin-top: 1.5625rem;
}
.centroAyudaItem {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid green; */
}
.centroAyudaItemTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: fit-content;
  color: #787878;
  /* border: 1px solid red; */
}
.centroAyudaItemChevron {
  padding-top: 0;
  font-size: 11px;
  padding-left: 0.5rem;
}
.centroAyudaItemChevron img {
  max-height: 13px;
}
.centroAyudaItemTitleSelected {
  color: #801b1c;
}
.centroAyudaItemTitleUnselected {
  color: #787878;
}
.centroAyudaText {
  margin-top: 0.9375rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #787878;
  width: 70%;
}
.centroAyudaHablemosText {
  padding-left: 1.5rem;
}
.centroAyudaHablemosWA {
  margin-top: 1.0625rem;
  width: 70%;
  /* display: flex;
  justify-content: center; */
}
.centroAyudaHablemosMsg {
  margin-top: 0.9375rem;
  border-radius: 10px;
  padding: 0.9375rem 0.875rem 0.9375rem 0.875rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #787878;
}
.centroAyudaHablemosMsg textarea {
  border: none;
  background: transparent;
}
.centroAyudaHablemosMsg textarea:focus {
  outline: none;
}
.centroAyudaItemBtn1 {
  margin-top: 0.9375rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
/* .centroAyudaHR {
  margin-top: 2.5625rem;
  background: #e5e5e5;
  height: 1px;
} */
.centroAyudaP_privacidad {
  margin-top: 2.5625rem;
}
.centroAyudaP_seguridad {
  margin-top: 2.5625rem;
}
.centroAyudaT_condiciones {
  margin-top: 2.5625rem;
}

.centroAyudaDocs {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  /* color: #787878; */
}
.centroAyudaBody {
  padding-bottom: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.cumpli_legales {
  padding-bottom: 6rem;
}
.centroAyudaGlosarioBody {
  margin-top: 0.9375rem;
}
.centroAyudaGlosarioItem {
  background: rgba(225, 225, 225, 0.4);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  padding: 0.55rem 1.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  min-height: 2.0625rem;
}
.centroAyudaGlosarioItem_unSelected {
  color: #787878;
}
.centroAyudaGlosarioItem_selected {
  color: #614ad3;
}

.centroAyudaT_condiciones_text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 13px;
  text-decoration: none;
  text-decoration-line: none !important;
}
.centroAyuda_term_cond_btn {
  margin-top: 1.9375rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.centro_ayuda_descarga {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3rem;
  color: #801b1c;
  padding-left: 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.centro_ayuda_descarga img{
  width: 2rem;
}
</style>