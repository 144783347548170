<template>
  <div class="manual_cod_etica_conductacont">
    <div class="ancho_acotado">
      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">
          {{ t("app.manual_cod_etica_conducta.intro") }}
        </div>
        {{ t("app.manual_cod_etica_conducta.intro_1") }}
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">
          {{ t("app.manual_cod_etica_conducta.proposito") }}
        </div>
        {{ t("app.manual_cod_etica_conducta.proposito_1") }}
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.vis_mis") }}</div>
        <div class="manual_cod_etica_conductaseparador">
          <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.vis_mis_nuestra_vis") }}</div>
          <div class="">{{ t("app.manual_cod_etica_conducta.vis_mis_nuestra_vis_1") }}</div>
        </div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.vis_mis_nuestra_mis") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.vis_mis_nuestra_mis_1") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.vis_mis_nuestra_mis_2") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores_respeto_perso") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.valores_respeto_perso_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores_exceder") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.valores_exceder_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores_respeto_medio") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.valores_respeto_medio_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores_buscar") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.valores_buscar_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores_integri") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.valores_integri_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.valores_gente") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.valores_gente_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.ambito_cod_etic") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.ambito_cod_etic_1") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.ambito_cod_etic_2_1") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.ambito_cod_etic_2_2") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.ambito_cod_etic_2_3") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.ambito_cod_etic_2_4") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.ambito_cod_etic_2_5") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.a_r_c_s_cod") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.a_r_c_s_cod_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.grup_inte") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_1") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_2") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_3") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_4") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_5") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_6") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_7") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.grup_inte_8") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.rel_lab") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.general") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.general_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.reclutam_selec") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.reclutam_selec_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.capacitacion") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.capacitacion_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.clientes") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.clientes_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.proveedores") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.proveedores_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.competencia") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.competencia_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.entorn_negoc") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.entorn_negoc_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.salud_seg_ocup") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.salud_seg_ocup_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.preven_alc_drog") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.preven_alc_drog_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.resp_med_amb") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.resp_med_amb_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.resp_derech_hum") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.resp_derech_hum_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.relac_func_public") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.relac_func_public_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.excl_neg_ileg") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.excl_neg_ileg_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.comport_comer") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.comport_comer_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.info_financ") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.info_financ_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.conflict_intereses") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.personal_colab") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.personal_colab_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.regalos_invit") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.regalos_invit_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.pol_donativ") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.pol_donativ_1") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.pol_donativ_2_1") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.pol_donativ_2_2") }}</div>
        <div class="manual_cod_etica_tab_left">-&nbsp;{{ t("app.manual_cod_etica_conducta.pol_donativ_2_3") }}</div>
        <div class="manual_cod_etica_conductaseparador">
          <div class="">{{ t("app.manual_cod_etica_conducta.pol_donativ_3") }}</div>
        </div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.pag_indeb_sobor_cohe") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.pag_indeb_sobor_cohe_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.resp_penal_empre") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.resp_penal_empre_1_a") }}&nbsp;{{ t("app.manual_cod_etica_conducta.resp_penal_empre_1_b") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.confiden_infor") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.confiden_infor_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.uso_inf_privil") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.uso_inf_privil_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.uso_nom_comp") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.uso_nom_comp_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.lineas_denun") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.lineas_denun_1") }}</div>
      </div>
   
      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.uso_correo_inter") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.uso_correo_inter_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.polit_comunic") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.marco_gene") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.marco_gene_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.comunic_inte") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.comunic_inte_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.comuni_ext") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.comuni_ext_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.comunic_oral") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.comunic_oral_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.info_autor_gob") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.info_autor_gob_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.protec_bienes_empre") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.protec_bienes_empre_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.medi_discip") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.medi_discip_1") }}</div>
      </div>

      <div class="manual_cod_etica_conductaseparador">
        <div class="manual_cod_etica_conductabold">{{ t("app.manual_cod_etica_conducta.adhe_cod_etic_cond") }}</div>
        <div class="">{{ t("app.manual_cod_etica_conducta.adhe_cod_etic_cond_1") }}</div>
      </div>


      
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    ...mapState(["montos_pais_docs"]),
  },
  methods: {
    ...mapActions([""]),

    format_number(numerito) {
      if (numerito && numerito != "") {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
    },
  },
};
</script>

<style>
.manual_cod_etica_conductacont {
  padding: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #383838;
}
.manual_cod_etica_conductacont_wide {
  padding-left: 1rem;
  padding-right: 1rem;
}
.manual_cod_etica_conductabold {
  font-weight: 500;
}
.manual_cod_etica_conductaseparador {
  padding-top: 1rem;
}
.faq__table1 {
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #383838;
}
.faq__table1 th {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #383838;
  width: 20%;
  max-width: 20%;
}
.manual_cod_etica_tab_left {
  padding-left: 1rem;
}

</style>