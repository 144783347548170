<template>
  <div class="faq__cont_ancho">
    <div class="faq__p1 faq__separador">
      {{ t("app.centro_ayuda.faq.montos_limit.t1") }}
    </div>
    <div class="faq__table1">
      <table>
        <thead>
          <th></th>
          <th>{{ t("app.centro_ayuda.faq.montos_limit.faq__table1.th_1") }}</th>
          <th>{{ t("app.centro_ayuda.faq.montos_limit.faq__table1.th_2") }}</th>
          <th>{{ t("app.centro_ayuda.faq.montos_limit.faq__table1.th_3") }}</th>
          <th>{{ t("app.centro_ayuda.faq.montos_limit.faq__table1.th_4") }}</th>
        </thead>
        <tbody>
          <tr v-for="(pais, w) in montos_pais_docs" :key="w">
            <td class="faq__table1_tr">{{ pais.pais }}</td>
            <td>{{ format_number(pais.min) }}</td>
            <td>{{ format_number(pais.max_giro) }}</td>
            <td>{{ format_number(pais.max_mes) }}</td>
            <td>{{ format_number(pais.max_year) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="faq__p1 faq__separador">
      {{ t("app.centro_ayuda.faq.montos_limit.t2") }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { useI18n } from "vue-i18n";


export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["montos_pais_docs"]),
  },
  methods: {
    ...mapActions([""]),

    format_number(numerito) {
      if (numerito && numerito != "") {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
    },
  },
};
</script>

<style>
.faq__table1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 1px solid green; */
  text-align: center;
}
.faq__table1 table {
  font-size: 10px;
}
.faq__table1_tr {
  text-align: left;
}
.faq__cont_ancho {
  /* padding-left: 0.8rem; */
  padding-bottom: 2rem;
}
</style>