<template>
  <div class="term_cond_cont ">
    <div class="ancho_acotado">
      <div class="">
        {{ t("app.term_cond.p_1") }}
      </div>
      <div class="term_cond_separador ">
        <span class="term_cond_bold">{{ t("app.term_cond.p_2_a") }}</span
        >&nbsp;
        {{ t("app.term_cond.p_2_b") }}
      </div>
      <div class="term_cond_separador ">
        1.-&nbsp;{{ t("app.term_cond.p_3_a") }}&nbsp;
        <span class="term_cond_bold">Girofacil&#174; </span>&nbsp;
        {{ t("app.term_cond.p_3_b") }}&nbsp;
        <span class="term_cond_bold">"Inversiones Girofacil SpA"</span>,&nbsp;
        {{ t("app.term_cond.p_3_c") }}&nbsp;
        <span class="term_cond_bold">Girofacil.</span>
      </div>

      <div class="term_cond_separador ">
        2.-&nbsp;{{ t("app.term_cond.p_4") }}
      </div>

      <div class="term_cond_separador ">
        3.-&nbsp;{{ t("app.term_cond.p_5_a") }},&nbsp;
        <span class="term_cond_bold">{{ t("app.term_cond.p_5_b") }}</span
        >,&nbsp;
        {{ t("app.term_cond.p_5_c") }}
      </div>

      <div class="term_cond_separador ">
        4.-&nbsp;{{ t("app.term_cond.p_6") }}
      </div>

      <div class="term_cond_separador ">
        5.-&nbsp;{{ t("app.term_cond.p_7") }}
      </div>

      <div class="term_cond_separador">
        <div class="">6.-&nbsp;{{ t("app.term_cond.p_8_a") }}</div>
        <div>{{ t("app.term_cond.p_8_b") }}</div>
        <div>{{ t("app.term_cond.p_8_c") }}</div>
      </div>

      <div class="term_cond_separador ">
        7.-&nbsp;{{ t("app.term_cond.p_9") }}
      </div>

      <div class="term_cond_separador ">
        8.-&nbsp;<span class="term_cond_bold"
          >{{ t("app.term_cond.p_10_a") }}:</span
        >&nbsp;
        {{ t("app.term_cond.p_10_b") }}&nbsp;sac@girofacil.com.&nbsp;{{ t("app.term_cond.p_10_b_") }}
      </div>

      <div class="term_cond_separador ">
        9.-&nbsp;{{ t("app.term_cond.p_11") }}
      </div>

      <div class="term_cond_separador ">
        10.-&nbsp;<span class="term_cond_bold"
          >{{ t("app.term_cond.p_12_a") }}:</span
        >&nbsp;
        {{ t("app.term_cond.p_12_b") }}
      </div>

      <div class="term_cond_separador ">
        11.-&nbsp;<span class="term_cond_bold"
          >{{ t("app.term_cond.p_13_a") }}:</span
        >&nbsp;
        {{ t("app.term_cond.p_13_b") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    ...mapState(["montos_pais_docs"]),
  },
  methods: {
    ...mapActions([""]),

    format_number(numerito) {
      if (numerito && numerito != "") {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
    },
  },
};
</script>

<style>
.term_cond_cont {
  padding: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #383838;
  /* border: 1px solid blue; */
  /* overflow-y: scroll; */
}
.term_cond_cont_wide {
  padding-left: 1rem;
  padding-right: 1rem;
}
.term_cond_bold {
  font-weight: 500;
}
.term_cond_separador {
  padding-top: 1rem;
}
.faq__table1 {
  /* padding-left: 1rem;
  padding-right: 1rem; */
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #383838;
}
.faq__table1 th {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #383838;
  width: 20%;
  max-width: 20%;
}
</style>