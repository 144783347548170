<template>
    <div  class="faq__cont">
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_general.t1") }}</div>
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_general.t2") }}</div>
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_general.t3") }}</div>
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_general.t4") }}</div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    setup() {
    const { t } = useI18n();
    return { t };
  },

}
</script>

<style>

</style>