<template>
  <div class="faq_cont body_16">
    <!-- <div class="faq_title" ref="faq_title">{{ $t("web.landing.faq.tit") }}</div> -->
    <div class="faq_body">
      <div ref="modo_uso_gf">
        <selectFaq
          class="faq_item"
          id="modo_uso_gf"
          :item_selected="item_selected"
          @setItemSelected="setItemSelected"
          :max_width="false"
        >
          <modo_uso_gf />
        </selectFaq>
      </div>

      <div ref="demora_general">
        <selectFaq
          class="faq_item"
          id="demora_general"
          :item_selected="item_selected"
          @setItemSelected="setItemSelected"
          :max_width="false"
        >
          <demora_general />
        </selectFaq>
      </div>

      <div ref="demora_colombia">
        <selectFaq
          class="faq_item"
          id="demora_colombia"
          :item_selected="item_selected"
          @setItemSelected="setItemSelected"
          :max_width="false"
        >
          <demora_colombia />
        </selectFaq>
      </div>

      <div ref="recup_pass">
        <selectFaq
          class="faq_item"
          id="recup_pass"
          :item_selected="item_selected"
          @setItemSelected="setItemSelected"
          :max_width="false"
        >
          <recup_pass />
        </selectFaq>
      </div>

      <!-- <div v-if="ver_mas"> -->
        <div ref="sucursales_caja_colombia">
          <selectFaq
            class="faq_item"
            id="sucursales_caja_colombia"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <sucursales_caja_colombia />
          </selectFaq>
        </div>
        <div ref="codigo_descuento">
          <selectFaq
            class="faq_item"
            id="codigo_descuento"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <codigo_descuento />
          </selectFaq>
        </div>
        <div ref="retiro_caja_pais">
          <selectFaq
            class="faq_item"
            id="retiro_caja_pais"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <retiro_caja_pais />
          </selectFaq>
        </div>
        <div ref="monto_banco">
          <selectFaq
            class="faq_item"
            id="monto_banco"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <monto_banco />
          </selectFaq>
        </div>
        <div ref="notif_estado_transf">
          <selectFaq
            class="faq_item"
            id="notif_estado_transf"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <notif_estado_transf />
          </selectFaq>
        </div>
        <div ref="datos_bco_gf">
          <selectFaq
            class="faq_item"
            id="datos_bco_gf"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <datos_bco_gf />
          </selectFaq>
        </div>
        <div ref="montos_limit">
          <selectFaq
            class="faq_item"
            id="montos_limit"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="true"
          >
            <montos_limit />
          </selectFaq>
        </div>

        <div ref="cotizar_giro">
          <selectFaq
            class="faq_item"
            id="cotizar_giro"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <cotizar_giro />
          </selectFaq>
        </div>
        <div ref="giro_no_llega">
          <selectFaq
            class="faq_item"
            id="giro_no_llega"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <giro_no_llega />
          </selectFaq>
        </div>

        <div ref="pago_otro_rut">
          <selectFaq
            class="faq_item"
            id="pago_otro_rut"
            :item_selected="item_selected"
            @setItemSelected="setItemSelected"
            :max_width="false"
          >
            <pago_otro_rut />
          </selectFaq>
        </div>
      <!-- </div> -->

      <div class="faq_ver_mas">
        <verMas
          :ver_mas="ver_mas"
          @ver_mas_toggle="ver_mas_toggle"
          :blur="false"
          back_color="ffffff"
        />
      </div>
    </div>
  </div>
</template>

<script>
import selectFaq from "./faqs/selectFaq.vue";
import modo_uso_gf from "./faqs/modo_uso_gf.vue";
import demora_general from "./faqs/demora_general.vue";
import demora_colombia from "./faqs/demora_colombia.vue";
import recup_pass from "./faqs/recup_pass.vue";
import sucursales_caja_colombia from "./faqs/sucursales_caja_colombia.vue";
import codigo_descuento from "./faqs/codigo_descuento.vue";
import retiro_caja_pais from "./faqs/retiro_caja_pais.vue";
import monto_banco from "./faqs/monto_banco.vue";
import notif_estado_transf from "./faqs/notif_estado_transf.vue";
import datos_bco_gf from "./faqs/datos_bco_gf.vue";
import montos_limit from "./faqs/montos_limit.vue";
import cotizar_giro from "./faqs/cotizar_giro.vue";
import giro_no_llega from "./faqs/giro_no_llega.vue";
import pago_otro_rut from "./faqs/pago_otro_rut.vue";
import {mapState} from 'vuex';

export default {
  components: {
    selectFaq,
    modo_uso_gf,
    demora_general,
    demora_colombia,
    recup_pass,
    sucursales_caja_colombia,
    codigo_descuento,
    retiro_caja_pais,
    monto_banco,
    notif_estado_transf,
    datos_bco_gf,
    montos_limit,
    cotizar_giro,
    giro_no_llega,
    pago_otro_rut,
  },
  data() {
    return {
      item_selected: "",
    };
  },
  computed:{
    ...mapState([""]),

  },
  methods: {
    setItemSelected(id) {
      this.item_selected = id;
      if (this.item_selected != "") {
        let elemento = this.$refs[id];
        setTimeout(() => {
          if (elemento && elemento.getBoundingClientRect().y < 0)
            elemento.scrollIntoView({
              block: "start",
              scrollBehavior: "smooth",
            });
        }, 50);
      }
    },
    
  },
};
</script>

<style>
.faq_cont {
  position: relative;
  overflow: hidden;
  /* border: 1px solid green; */
  width: 100%;
}
.faq_title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #383838;
}
.faq_body {
  padding-top: 1rem;
}
.faq_item {
  margin: 0.5rem 0;
}
.faq_ver_mas {
  background: white;
}
.faq__t_azul a:visited {
  color:var(--customC-614AD3);
}

/* ********************************************** */
@media (min-width: 500px) {
  .descarga_title {
    text-align: center;
  }
}

/* ********************************************** */
@media (min-width: 768px) {
  /* .faq_cont {
    padding-left: 10%;
    padding-right: 10%;
  } */
}

/* ********************************************** */
@media (min-width: 1200px) {
  .faq_title {
    font-size: 40px;
    line-height: 47px;
  }
  /* .faq_cont {
    padding-left: 20%;
    padding-right: 20%;
  } */
}
</style>