<template>
  <div class="selectFaq_">
    <div class="selectFaq_select" @click.stop="setItemSelected">
      <div class="selectFaq_req">
        {{ t(`app.centro_ayuda.faq.${id}.req`) }}
      </div>
      <div
        class="selectFaq_icon_req"
        :class="{ selectFaq_icon_res: item_selected == id }"
      >
        <img :src="icon_select" alt="" />
      </div>
    </div>
    <div :class="max_width ? 'selectFaq_resp_ancho' : 'selectFaq_resp'" v-if="item_selected == id">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    item_selected: String,
    id: String,
    max_width: Boolean
  },
  data() {
    return {
      icon_select: require("../../../assets/imgs/centroAyuda/iconChevronAbajoGris.svg"),
    };
  },
  methods: {
    setItemSelected() {
      if (this.item_selected == this.id) {
        this.$emit("setItemSelected", "");
      } else {
        this.$emit("setItemSelected", this.id);
      }
    },
  },
};
</script>

<style>
.selectFaq_ {
  background: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  min-height: 2.1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #4c4c4c;
}
.selectFaq_select {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.selectFaq_req {
  padding-right: 1.1rem;
}
.selectFaq_icon_req {
  transform: rotate(0deg);
  transition: all 0.5s;
  width: 0.8rem;
  min-width: 0.8rem;
  /* border: 1px solid blue; */
}
.selectFaq_icon_res {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.selectFaq_resp {
  padding: 1rem 3rem 1rem 1rem;
  font-weight: 400;
  color: #787878;
}
.selectFaq_resp_ancho{
  padding: 1rem 0.5rem 1rem 0.5rem;
  font-weight: 400;
  color: #787878;
}

.faq__cont{
    padding-left: 0.8rem;
    /* padding-bottom: 2rem; */
}
.faq__separador{
    padding: 0.4rem 0;
}
.faq__puntos_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.faq__puntos_row span{
    color: var(--customC-614AD3);
    
}
.faq__puntos_simbol{
    padding: 0 0.6rem;
}
.faq__t_cortado{
    word-break: break-all;
}
.faq__destgacado{
    font-weight: 500;
}

/* ********************************************** */
@media (min-width: 768px) {
  .selectFaq_req {
    font-size: 16px;
    line-height: 20px;
  }
}

/* ********************************************** */
@media (min-width: 1200px) {
  .selectFaq_req {
    font-size: 20px;
    line-height: 30px;
  }
}
</style>