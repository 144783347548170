<template>
    <div  class="faq__cont">
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_colombia.t1") }}</div>
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_colombia.t2") }}</div>
        <div class="faq__p1 faq__separador">{{ t("app.centro_ayuda.faq.demora_colombia.t3") }}</div>
        <div class="faq__p1 faq__separador">
            <div class="faq__puntos_row  faq__separador">
                <div class="faq__puntos_simbol">&bull;</div>
                <div class="faq__puntos_text">
                    {{ t("app.centro_ayuda.faq.demora_colombia.t4_a") }}&nbsp;
                    <span class="faq__t_azul faq__t_cortado"><a href="https://www.bancow.com.co/canales-de-servicio/nuestras-oficinas/" target="_blank">https://www.bancow.com.co/canales-de-servicio/nuestras-oficinas/</a> </span>&nbsp;
                    {{ t("app.centro_ayuda.faq.demora_colombia.t4_b") }}
                </div>
            </div>
            <div class="faq__puntos_row  faq__separador">
                <div class="faq__puntos_simbol">&bull;</div>
                <div class="faq__puntos_text">
                    {{ t("app.centro_ayuda.faq.demora_colombia.t5_a") }}&nbsp;
                    <span class="faq__t_azul faq__t_cortado">018000515656</span>&nbsp;
                    {{ t("app.centro_ayuda.faq.demora_colombia.t5_b") }}
                </div>
            </div>
            <div class="faq__puntos_row  faq__separador">
                <div class="faq__puntos_simbol">&bull;</div>
                <div class="faq__puntos_text">
                    {{ t("app.centro_ayuda.faq.demora_colombia.t6_a") }}&nbsp;
                    <span class="faq__t_azul faq__t_cortado"><a href="https://www.bancow.com.co/giros-internacionales/reclamar-giros/depositos-bancarios/" target="_blank">https://www.bancow.com.co/giros-internacionales/reclamar-giros/depositos-bancarios/</a></span>&nbsp;
                    {{ t("app.centro_ayuda.faq.demora_colombia.t6_b") }}&nbsp;
                    <span class="faq__t_azul">consignaciones@bancow.com.co</span>
                </div>
            </div>
            <div class="faq__puntos_row  faq__separador">
                <div class="faq__puntos_simbol">&bull;</div>
                <div class="faq__puntos_text">
                    {{ t("app.centro_ayuda.faq.demora_colombia.t7") }}
                </div>
            </div>
        </div>
        <div class="faq__p1 faq__separador faq__destgacado">{{ t("app.centro_ayuda.faq.demora_colombia.t8") }}</div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    setup() {
    const { t } = useI18n();
    return { t };
  },

}
</script>

<style>

</style>